import {Modal as ModalContract, ModalOptions} from "../../types";

export default class Modal implements ModalContract {
  rootElement: HTMLElement;

  constructor(rootElement: HTMLElement | string, modalOptions: ModalOptions = {}) {
    if (rootElement instanceof HTMLElement) {
      this.rootElement = rootElement;
    } else {
      this.rootElement = <HTMLElement> document.querySelector(rootElement);
    }
  }
}
