export enum CookieType {
  ADS = 'ad_storage',
  ANALYTICS = 'analytics_storage',
  FUNCTIONALITY = 'functionality_storage',
}

export enum CookieConsentGrant {
  DENIED = 'denied',
  GRANTED = 'granted',
}
