import {Cookie as CookieModal} from "./cookie";
import {CookieType} from "../../enums";

class CookiePreferences extends CookieModal
{
  constructor() {
    super('#cookie-preferences');
  }

  formElement(): HTMLFormElement {
    return <HTMLFormElement> this.rootElement.querySelector('form');
  }

  getTypes(): CookieType[] {
    const formData: FormData = new FormData(this.formElement());
    const cookieTypes = [];
    formData.forEach((value: FormDataEntryValue, key: string): void => {
      if (value.toString() !== '1') {
        return;
      }

      const cookieType: CookieType = <CookieType> key.replace('cookie_consent_', '');
      cookieTypes.push(cookieType);
    });

    if (! cookieTypes.includes(CookieType.FUNCTIONALITY)) {
      // Can't be turned off
      cookieTypes.push(CookieType.FUNCTIONALITY);
    }

    return cookieTypes;
  }

  setTypes(types: CookieType[]): void {
    if (! types.includes(CookieType.FUNCTIONALITY)) {
      // Can't be turned off
      types.push(CookieType.FUNCTIONALITY);
    }

    const inputs: HTMLInputElement[] =
      <HTMLInputElement[]> Array.from(this.rootElement.querySelectorAll('[attribute^="cookie_consent_"]'));

    inputs.forEach((input: HTMLInputElement) => {
      input.checked = false;
    });

    types.forEach((type: CookieType) => {
      const input: HTMLInputElement = <HTMLInputElement> this.rootElement
        .querySelector(`[name="cookie_consent_${type}"]`);

      input.checked = true;
    });
  }
}

let cookiePreferencesInstance: CookiePreferences | undefined;
const cookiePreferences = () => {
  if (typeof cookiePreferencesInstance === typeof undefined) {
    cookiePreferencesInstance = new CookiePreferences();
  }
  return cookiePreferencesInstance;
};

export { cookiePreferences as default, cookiePreferences, CookiePreferences };
