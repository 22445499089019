import {CookieConsent as CookieConsentContract} from "../types";
import {
  cookieConsent as cookieConsentModal,
  CookieConsent as CookieConsentModal,
} from "./modals/cookieConsent";
import {
  cookiePreferences as cookiePreferencesModal,
  CookiePreferences as CookiePreferencesModal,
} from "./modals/cookiePreferences";
import {CookieConsentGrant, CookieType} from "../enums";
// @ts-ignore
import Cookies from 'js-cookie';

class CookieConsent implements CookieConsentContract {
  cookieConsentModal: CookieConsentModal | undefined;
  cookiePreferencesModal: CookiePreferencesModal | undefined;

  constructor() {
    this.cookieConsentModal = cookieConsentModal();
    this.cookiePreferencesModal = cookiePreferencesModal();

    this.cookiePreferencesModal.setTypes(this.grantedTypes());
    if (this.shouldShowGate()) {
      this.showGate();
    } else {
      this.propagateToGtm();
    }

    this.listen();
  }
  protected listen(): void {
    window.addEventListener('hashchange', (): void => { return this.lHashchange(); });
  }

  protected lHashchange(): void {
    if (window.location.hash == '#cookie-accept-strictly-necessary') {
      this.acceptStrictlyNecessaryCookies();
      return;
    }

    if (window.location.hash == '#cookie-accept-all') {
      this.acceptAllCookies();
      return;
    }

    if (window.location.hash == '#cookie-accept-selection') {
      this.acceptSelection();
      return;
    }
  }

  protected acceptStrictlyNecessaryCookies(): void {
    this.acceptCookies([
      CookieType.FUNCTIONALITY,
    ]);

    window.location.hash = 'cookie-consent-given';
  }

  protected acceptAllCookies(): void {
    this.acceptCookies([
      CookieType.FUNCTIONALITY,
      CookieType.ANALYTICS,
      CookieType.ADS,
    ]);

    window.location.hash = 'cookie-consent-given';
  }

  protected acceptSelection(): void {
    this.acceptCookies(this.cookiePreferencesModal.getTypes());

    window.location.hash = 'cookie-consent-given';
  }

  protected acceptCookies(types: CookieType[]): void {
    const grants = {};

    for (let cookieTypeKey in CookieType) {
      grants[CookieType[cookieTypeKey]] = CookieConsentGrant.DENIED;
    }

    types.forEach((cookieType: string): void => {
      grants[cookieType] = CookieConsentGrant.GRANTED;
    });

    for (let key in grants) {
      Cookies.set(`cookie_consent_${key}` , grants[key], { expires: 180 });
    }

    this.propagateToGtm();
  }

  protected grants(): {} {
    const grants: {} = {};

    for (let cookieTypeKey in CookieType) {
      grants[CookieType[cookieTypeKey]] = CookieConsentGrant.DENIED;
    }

    for (let key in grants) {
      const cookieValue = Cookies.get(`cookie_consent_${key}`);
      if (Object.values(CookieConsentGrant).includes(cookieValue)) {
        grants[key] = cookieValue;
      }
    }

    return grants;
  }

  protected grantedTypes(): CookieType[] {
    const types: CookieType[] = [];

    for (let key in this.grants()) {
      if (this.grants()[key] === CookieConsentGrant.GRANTED) {
        types.push(<CookieType> key);
      }
    }

    return types;
  }

  protected propagateToGtm(): void {
    let dataLayer = window.dataLayer || [];
    dataLayer.push(['consent', 'update', this.grants()]);
  }

  protected shouldShowGate(): boolean {
    const functionalityValue: string = Cookies.get(`cookie_consent_${CookieType.FUNCTIONALITY}`);

    return functionalityValue !== CookieConsentGrant.GRANTED && window.location.hash !== '#cookie-consent';
  }

  protected showGate(): void {
    window.location.hash = 'cookie-consent';
  }
}

let cookieConsentInstance: CookieConsent | undefined;
const cookieConsent = () => {
  if (typeof cookieConsentInstance === typeof undefined) {
    cookieConsentInstance = new CookieConsent();
  }
  return cookieConsentInstance;
};

export { cookieConsent as default, cookieConsent, CookieConsent };
