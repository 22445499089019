import { Cookie as CookieModal } from "./cookie";

class CookieConsent extends CookieModal
{
  constructor() {
    super('#cookie-consent');
  }
}

let cookieConsentInstance: CookieConsent | undefined;
const cookieConsent = () => {
  if (typeof cookieConsentInstance === typeof undefined) {
    cookieConsentInstance = new CookieConsent();
  }
  return cookieConsentInstance;
};

export { cookieConsent as default, cookieConsent, CookieConsent };
